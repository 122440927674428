import 'bootstrap';
import Splide from '@splidejs/splide';
require('~/styles/normalize.css')
require('~/styles/index.scss');

new Splide( '.splide', {
  direction: 'ttb',
  type: 'loop',
  height   : '370px',
  wheel    : true,
  perPage: 5,
  perMove: 2,
  focus: 'center',
  arrows: false,
  pagination: false,
  trimSpace: false
}).mount();
